<!-- tabs组件 -->
<template>
  <div ref="tabs" v-if="list && list.length > 0" class="tabs">
    <div
        ref="tab"
        v-for="item in tabs"
        :key="item[id]"
        class="tab"
        :class="{'tab__active': item[id] === activeItem[id]}"
        :style="tabStyles"
        @click="handleClick(item)">
      {{ item[label] }}
    </div>
    <el-button
        v-if="showCount !== 0"
        style="margin-top: -6px"
        type="text"
        @click="isShowMore = !isShowMore">
      {{ isShowMore ? '更多>>' : '收起' }}
    </el-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeItem: {},
      // showCount: 2,
      isShowMore: true
    }
  },
  props: {
    value: [Number, String], // 双向绑定的值
    list: Array, // 要显示的列表
    label: { // 默认label名
      type: String,
      default: 'label'
    },
    id: { // 默认value名
      type: String,
      default: 'value'
    },
    size: { // 大小(small, normal, large)
      type: String,
      default: 'normal'
    },
    showCount: { // 大小(small, normal, large)
      type: Number,
      default: 0
    },
    showAll: { // 是否展示全部
      type: Boolean,
      default: true
    },
    activeFirst: { // 是否激活第一个
      type: Boolean,
      default: false
    },
    autoWidth: { // 宽度是否自动
      type: Boolean,
      default: true
    },
    width: {
      type: String
    },
    marginRight: {
      type: Number,
      default: 10
    }
  },
  computed: {
    tabStyles () {
      let styles = {
        marginRight: this.marginRight + 'px'
      }
      if (this.size === 'small') {
        styles = {
          ...styles,
          fontSize: '12px',
          padding: '6px 12px'
        }
      } else if (this.size === 'normal') {
        styles = {
          ...styles,
          fontSize: '14px',
          padding: '8px 13px'
        }
      } else if (this.size === 'large') {
        styles = {
          fontSize: '16px',
          padding: '10px 20px'
        }
      }
      if (!this.autoWidth) {
        styles = {
          ...styles,
          textAlign: 'center',
          overflow: 'hidden',
        }
        if (this.width){
          styles = {
            ...styles,
            width: this.width
          }
        } else {
          styles = {
            ...styles,
            flex: 1
          }
        }
      }
      return styles
    },
    tabs () {
      let list = []
      if (this.showAll) {
        list.push({
          [this.label]: '全部',
          [this.id]: ''
        })
      }
      list = [...list, ...this.list]
      if (this.showCount !== 0 && this.isShowMore) {
        list.splice(this.showCount)
      }
      return list
    }
  },
  created() {
    if (this.showAll) {
      this.activeItem = {
        [this.label]: '全部',
        [this.id]: ''
      }
    }
    // if (this.activeFirst) {
    //     this.activeItem = {
    //         [this.label]: this.list[0][this.label],
    //         [this.id]: this.list[0][this.id]
    //     }
    // }
  },
  mounted() {
    const tabsRef = this.$refs.tabs
    if (tabsRef) {
      const tabsWidth = tabsRef.offsetWidth
      const tabRefs = this.$refs.tab
      if (tabRefs && tabRefs.length > 0) {
        let tempWidth = 0
        for (let index in tabRefs) {
          let tab = tabRefs[index]
          let tabWidth = tab.offsetWidth + this.marginRight
          tempWidth += tabWidth
          if (tempWidth >= tabsWidth) {
            this.showCount = index - 1
          }
        }
      }
    }
  },
  methods: {
    handleClick (item) {
      this.activeItem = item
      this.$emit('input', item[this.id])
      this.$emit('change', item)
    }
  }
}
</script>

<style scoped lang="scss">
  .tabs{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    line-height: 1;
    .tab{
      background-color: transparent;
      color: #666;
      cursor: pointer;
      transition: all .1s ease-in-out;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 6px;
      &:hover{
        background-color: #ddd;
      }
      &__active{
        background-color: #ff9123 !important;
        color: #fff;
      }
    }
  }
</style>
