<template>
  <el-dialog
    title="选择意向产品"
    append-to-body
    :visible.sync="visible"
    width="70%"
    v-loading="loading"
    @close="closeCallback">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList(true)">
      <el-form-item>
        <el-input v-model="dataForm.itemName" placeholder="请选择" readonly></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="dataForm.serviceNo" placeholder="产品编号" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="dataForm.serviceName" placeholder="产品名称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" circle size="small" @click="getDataList(true)"></el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      size="small"
      style="width: 100%;">
      <el-table-column
        type="index"
        header-align="center"
        align="center"
        width="50"
        label="#">
      </el-table-column>
      <el-table-column
        prop="serviceNo"
        header-align="center"
        align="center"
        label="产品编号">
      </el-table-column>
      <el-table-column
        prop="serviceName"
        header-align="center"
        align="center"
        label="产品名称">
      </el-table-column>
      <el-table-column
        prop="itemDesc"
        header-align="center"
        align="center"
        label="产品简介">
      </el-table-column>
      <el-table-column
        width="80"
        header-align="center"
        align="center"
        label="操作">
        <template slot-scope="scope">
          <el-popover
            ref="popover"
            placement="right"
            width="100"
            trigger="hover"
            content="点击选择该产品">
            <el-button size="mini" slot="reference" type="success" icon="el-icon-check" circle @click="choseProduct(scope.row)"></el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 10px;text-align: right"
      small
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, prev, pager, next, jumper">
    </el-pagination>
  </el-dialog>
</template>

<script>
  import {checkIsNull} from '@/utils'

  export default {
    name: 'item-product',
    data () {
      return {
        visible: false,
        loading: false,
        dataForm: {
          itemCode: '',
          itemName: '',
          serviceNo: '',
          serviceName: ''
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false
      }
    },
    mounted () {
      this.visible = true
    },
    methods: {
      init (code, name) {
        this.dataForm.itemCode = code
        this.dataForm.itemName = name
        this.getDataList(true)
      },
      getDataList (refresh) {
        if (checkIsNull(this.dataForm.itemCode)) {
          this.$message.warning('请选择服务项')
          return
        }
        if (refresh) {
          this.pageIndex = 1
        }
        this.dataListLoading = true
        this.$http({
          url: this.$http.adornUrl(this.$api.PRODUCT.SERVICE.LIST),
          method: 'post',
          params: this.$http.adornParams({
            'page': this.pageIndex,
            'limit': this.pageSize,
            'serviceNo': this.dataForm.serviceNo,
            'serviceName': this.dataForm.serviceName,
            'itemCode': this.dataForm.itemCode
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList(false)
      },
      choseProduct (rowData) {
        this.loading = true
        this.$http({
          url: this.$http.adornUrl(this.$api.PRODUCT.SERVICE.INFO + '/' + rowData.id),
          method: 'post'
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.visible = false
            this.$emit('choseProduct', data.serviceInfo)
          }
        })
      },
      closeCallback () {
        this.visible = false
        this.$emit('closeCallback')
      }
    }
  }
</script>

<style scoped>

</style>
