<template>
  <div>
    <el-cascader
      ref="orgCascader"
      :style="customStyle"
      placeholder="请选择机构"
      v-model="checkData"
      :props="props"
      clearable
      :show-all-levels="false"
      @change="changeListen"
    >
    </el-cascader>
  </div>
</template>

<script>
  export default {
    name: 'orgSelectPopover',
    components: {
    },
    props: {
      // 是否初始化返回组件值
      initCallback: {
        type: Boolean,
        default: true
      },
      // 有默认选择
      defaultCheck: {
        type: Boolean,
        default: true
      },
      // 自定义样式
      customStyle: {
        type: String,
        default: () => {
          return ''
        }
      }
    },
    data () {
      return {
        // 选择器配置字段
        props: {
          label: 'orgName',
          value: 'orgCode',
          children: 'children',
          leaf: 'isLeaf',
          lazy: true,
          lazyLoad: this.loadChildOrg,
          checkStrictly: true
        },
        initOrg: {
          orgCode: '00',
          orgName: '全国',
          isLeaf: false
        },
        checkData: []
      }
    },
    mounted () {
      if (this.initCallback) {
        this.$emit('clickItem', this.initOrg.orgCode, this.initOrg.orgName)
      }
      if (!this.defaultCheck) {
        this.checkData = []
      }
    },
    methods: {
      // 懒加载子机构
      loadChildOrg (node, resolve) {
        // 初始化根节点
        if (node.level === 0) {
          return resolve([this.initOrg])
        }
        if (node.data.isLeaf) {
          return resolve([])
        }
        // 查找子节点
        this.$http({
          url: this.$http.adornUrl(this.$api.GB.ORG.FIND_CHILD_ORG),
          method: 'get',
          params: this.$http.adornParams({
            parentId: node.data.orgCode
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            data.list.map((item) => {
              item.isLeaf = item.isLeaf === 1
            })
            return resolve(data.list)
          } else {
            this.$message.warning('查找' + node.data.orgName + '的子机构时出错')
            return resolve([])
          }
        }).catch(() => {
          return resolve([])
        })
      },
      /**
       * 数据变化监听
       * @param val 数组形式['00', '37', '3701', ...]
       */
      changeListen (val) {
        if (val.length === 0) {
          if (!this.defaultCheck) {
            this.checkData = []
            val = []
          } else {
            this.checkData = [this.initOrg.orgCode]
            val = [this.initOrg.orgCode]
          }
        }
        const _this = this
        setTimeout(function () {
          const orgName = _this.$refs.orgCascader.inputValue
          _this.$emit('clickItem', val.length > 0 ? val[val.length - 1] : '', orgName)
        }, 50)
        _this.$refs.orgCascader.toggleDropDownVisible()
      }
    }
  }
</script>

<style scoped>

</style>
