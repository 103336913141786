<template>
    <div class="container">
        <el-form ref="form" class="form-box" :model="dataForm" label-width="90px" size="small" label-position="left">
            <el-form-item label="行业分类：" prop="itemCode">
                <zx-tabs v-model="dataForm.itemCode" :list="serviceItemList" label="itemName" id="itemCode"></zx-tabs>
            </el-form-item>
            <el-form-item label="服务分类：" prop="serviceLabel">
                <zx-tabs v-model="dataForm.serviceLabel" :list="serviceMethodDict" label="dictName" id="dictId"></zx-tabs>
            </el-form-item>
            <el-form-item label="所在地：" prop="orgCode">
                <zx-tabs v-model="dataForm.orgCode" :list="provinces" label="orgName" id="orgCode" :show-count="14"></zx-tabs>
            </el-form-item>
            <div class="uf uf-ac" style="position: relative">
                <div class="search-line-label" style="width: 62px">
                    <span>排序</span>
                </div>
                <div class="mr-3">
                    <el-radio-group v-model="dataForm.sortType" @change="searchData">
                        <el-radio-button label="ALL">综合排序</el-radio-button>
                        <el-radio-button label="TIME">最新</el-radio-button>
                    </el-radio-group>
                </div>
                <!--          <div class="search-line-label ml-5" style="margin-right: 5px">-->
                <!--            <span>价格</span>-->
                <!--          </div>-->
                <!--          <div class="uf uf-ac search-price" style="position: relative;padding-right: 52px" @mouseenter="priceEnterVisible = true" @mouseleave="priceEnterVisible = false">-->
                <!--            <el-input class="mr-1" style="width: 65px;text-align: left" size="mini" v-model="dataForm.priceLow" placeholder="￥" v-enter-int clearable></el-input>-->
                <!--            <span>-</span>-->
                <!--            <el-input class="ml-1" style="width: 65px;text-align: left" size="mini" v-model="dataForm.priceHigh" placeholder="￥" v-enter-int clearable></el-input>-->
                <!--            <el-button v-show="priceEnterVisible" style="position: absolute;right: 0" type="primary" size="mini" @click="searchData">确认</el-button>-->
                <!--          </div>-->
                <!--          <div class="search-line-label ml-4" style="margin-right: 5px">-->
                <!--            <span>服务地区</span>-->
                <!--          </div>-->
                <!--          <div>-->
                <!--            <org-select-popover></org-select-popover>-->
                <!--          </div>-->
                <div>
                    <el-button type='primary' icon="el-icon-edit-outline" plain @click="addOrFollow">发布需求</el-button>
                </div>
                <div class="uf uf-ac search-btn ml-3 uf-f1">
                    <el-input placeholder="请输入搜索内容" class="mr-2" clearable v-model="searchVal"
                              @keyup.enter.native="getDataList(true)"></el-input>
                    <el-button type="primary" class="button" icon="el-icon-search" @click="getDataList(true)">搜索
                    </el-button>
                </div>
                <div class="uf uf-ac">
                    <el-divider direction="vertical"></el-divider>
                    <div class="ml-2">
                        共 <span class="fwb f18 c-base">{{ totalCount }}</span> 件产品
                    </div>
                    <div class="ml-3 mr-2">
                        <span class="c-base">{{ pageIndex }}</span>
                        <span>/</span>
                        <span>{{ totalPage }}</span>
                    </div>
                    <el-pagination
                        small
                        background
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="pageIndex"
                        :page-size="pageSize"
                        :total="totalCount"
                        layout="prev, next">
                    </el-pagination>
                </div>
            </div>
        </el-form>
        <div class="u-flex u-col-top">
            <div class="u-flex-1 u-flow-hidden">
                <el-table
                        :data="dataList"
                        size="small"
                        stripe
                        style="width: 100%">
                    <el-table-column prop="needName" label="需求名称" min-width="180"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="itemName" label="行业类别" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="serviceLabel" label="服务分类" width="90" align="center">
                        <template v-slot="{row}">
                            {{serviceMethodDictMap[row.serviceLabel]}}
                        </template>
                    </el-table-column>
<!--                    <el-table-column prop="status" label="状态" align="center">-->
<!--                        <template v-slot="{row}">-->
<!--                            {{ needDictMap[row.status] }}-->
<!--&lt;!&ndash;                            <zx-tag v-if="row.status === 'dgj'" color="#ff1f1f"> {{ needDictMap[row.status] }}</zx-tag>&ndash;&gt;-->
<!--&lt;!&ndash;                            <zx-tag v-else-if="row.status === 'dyhqr'" color="#ff6a00"> {{ needDictMap[row.status] }}</zx-tag>&ndash;&gt;-->
<!--&lt;!&ndash;                            <zx-tag v-else-if="row.status === 'ywc'" color="#009DFF"> {{ needDictMap[row.status] }}</zx-tag>&ndash;&gt;-->
<!--&lt;!&ndash;                            <zx-tag v-else-if="row.status === 'ycd'" color="#00A139"> {{ needDictMap[row.status] }}</zx-tag>&ndash;&gt;-->
<!--&lt;!&ndash;                            <zx-tag v-else color="#757575"> {{ needDictMap[row.status] }}</zx-tag>&ndash;&gt;-->
<!--                        </template>-->
<!--                    </el-table-column>-->
                    <el-table-column prop="orgName" label="所在地区" min-width="110" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="createTime" label="发布日期" min-width="120" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column label="状态" min-width="120" align="center" fixed="right">
                        <template v-slot="scope">
                            <template v-if="scope.row.status === 'dgj'">
                                <a :href="'/manage-back/#/need-need-pending?needNo='+ scope.row.needNo" target="_blank" >
                                    <el-button type="primary" plain size="small">待跟进</el-button>
                                </a>
                            </template>
                            <template  v-else>
                                <div>{{ scope.row.statusName }}</div>
<!--                                <el-button type="primary" plain size="small">{{ needDictMap[scope.row.status] }}</el-button>-->
                            </template>
                        </template>

                    </el-table-column>
                </el-table>
            </div>
<!--            <div class="right">-->
<!--                <right-nav :data="hotList"></right-nav>-->
<!--            </div>-->
        </div>
        <div class="pagination u-flex u-row-center u-col-center u-m-t-20">
            <el-pagination
                background
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="totalCount"
                layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
        </div>
        <need-or-add v-if="addOrUpdateVisible" ref="addNeed" @closeCallback="closeCallback"/>
    </div>
</template>

<script>
import ZxTabs from '@/components/ZxTabs/index.vue'
// import ZxTag from '@/components/zx-tag/zx-tag.vue'
// import ZxSearch from '@/components/ZxSearch/index.vue'
// import ZxSort from '@/components/ZxSort/index.vue'
// import RightNav from '@/views/index/demand-hall/modules/RightNav.vue'
import NeedOrAdd from '@/views/manage/modules/need/need-or-add'
import {checkIsNull} from '../../../utils'
// import routerTransfer from "@/utils/routerTransfer"

export default {
    components: {NeedOrAdd, ZxTabs},
    data() {
        return {
            visible: false,
            maxLevel: 1,
            expandOption: {},
            serviceItemChoose: {},
            serviceItemList: [],
            provinces: [],
            itemDataList: {},
            searchVal: '',
            dataForm: {
                needName: '',
                itemCode: '',
                sortType: '',
                serviceLabel: '',
                orgCode: '',
                priceLow: null,
                priceHigh: null
            },
            loading: false,
            dataList: [],
            pageIndex: 1,
            pageSize: 20,
            totalCount: 0,
            totalPage: 0,
            indexLen: {
                1: {len: 2, title: '行业分类'},
                2: {len: 4, title: '二级目录'},
                3: {len: 6, title: '三级目录'},
                4: {len: 8, title: '四级目录'},
                5: {len: 12, title: '五级目录'}
            },
            serviceMethodDict: [],
            serviceMethodDictMap: {},
            needDictMap: {},
            priceEnterVisible: false,
            addOrUpdateVisible: false,
            isGetHotList: true,
            hotList: [],
        }
    },
    computed: {
        //接受路由传参 服务产品channel_item_code获取服务详情
        id() {
            return this.$route.query.id
        },
        //存取用户登录状态
        loginStatus: {
            get() {
                return this.$store.state.user.loginStatus
            }
        },
        keepAliveArr: {
            get() {
                return this.$store.state.common.keepAliveArray
            },
            set(arr) {
                this.$store.commit('common/updateKeepAliveArray', arr)
            }
        }
    },
    watch: {
        dataForm: {
            deep: true,
            handler (val) {
                this.getDataList(true)
            }
        }
    },
    created() {
        this.$getDictList('SERVICE_LABEL,NEED_STATUS', (dict) => {
            this.serviceMethodDict = dict.SERVICE_LABEL
        })
        this.$getDictMap('SERVICE_LABEL,NEED_STATUS', dict => {
            this.serviceMethodDictMap = dict['SERVICE_LABEL']
            this.needDictMap = dict['NEED_STATUS']
        })
        this.getProvince()
        this.dataForm.itemCode = this.$route.params.itemCode || ''
        this.dataForm.needName = this.$route.params.needName || ''
        this.getServiceItemMenu()
    },
    mounted() {
        // routerTransfer.$on('searchEvent', function (data) {
        //     this.dataForm.serviceName = data.serviceName
        //     this.getDataList(true)
        // }.bind(this))
        this.getDataList(true)
    },
    beforeRouteLeave(to, from, next) {
        if (to.name === 'service') {
            this.keepAliveArr = ['search']
        }
        next()
    },
    methods: {
        // 获取省份
        getProvince() {
            // 查找子节点
            this.$http({
                url: this.$http.adornUrl(this.$api.GB.ORG.FIND_CHILD_ORG),
                method: 'get',
                params: this.$http.adornParams({
                    parentId: '00'
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.provinces = data.list
                }
            })
        },
        // 获取服务目录
        getServiceItemMenu() {
            // let menu = localStorage.getItem('serviceItemMenu')
            // console.log(menu)
            // if (!checkIsNull(menu)) {
            //     let menuObj = JSON.parse(menu)
            //     this.serviceItemList = menuObj.menu
            //     this.maxLevel = menuObj.maxLevel || 1
            //     this.handleExpandOption()
            //     this.visible = true
            //     return
            // }
            this.$http({
                url: this.$http.adornUrl(this.$api.PUB.MENU),
                method: 'get'
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.serviceItemList = data.menu.menu
                    this.maxLevel = data.menu.maxLevel || 1
                    // localStorage.setItem('serviceItemMenu', JSON.stringify(data.menu))
                    // this.handleExpandOption()
                }
                this.visible = true
            }).catch(() => {
                this.visible = true
            })
        },
        // 处理服务类别条件栏
        handleExpandOption() {
            for (let i = 0; i < this.maxLevel; i++) {
                this.expandOption['expand' + (i + 1)] = false
                this.serviceItemChoose['itemChoose' + (i + 1)] = {itemCode: 'all', itemName: '全部'}
                this.itemDataList['dataList' + (i + 1)] = []
            }
            this.handleItem()
        },
        handleItem() {
            let itemCode = this.dataForm.itemCode
            // 处理每层选择的itemCode
            for (let i = 1; i <= this.maxLevel; i++) {
                if (!checkIsNull(itemCode) && itemCode.length >= this.indexLen[i].len) {
                    let thisItemCode = itemCode.substring(0, this.indexLen[i].len)
                    if (i === 1) {
                        this.serviceItemChoose['itemChoose' + i] = this.serviceItemList.filter(item => item.itemCode === thisItemCode)[0]
                    } else {
                        this.serviceItemChoose['itemChoose' + i] = this.serviceItemChoose['itemChoose' + (i - 1)].children.filter(item => item.itemCode === thisItemCode)[0]
                    }
                } else {
                    this.serviceItemChoose['itemChoose' + i] = {itemCode: 'all', itemName: '全部'}
                }
            }
            // 处理每层的数据
            for (let i = 1; i <= this.maxLevel; i++) {
                if (i === 1) {
                    this.itemDataList['dataList' + i] = this.$deepClone(this.serviceItemList)
                } else {
                    if (this.serviceItemChoose['itemChoose' + (i - 1)].itemCode !== 'all') {
                        this.itemDataList['dataList' + i] = this.serviceItemChoose['itemChoose' + (i - 1)].children
                    } else {
                        this.itemDataList['dataList' + i] = []
                    }
                }
            }
            this.$forceUpdate()
        },
        // 是否展开所有条件
        switchExpand(index) {
            this.expandOption['expand' + index] = !this.expandOption['expand' + index]
            this.$forceUpdate()
        },
        // 选择服务类别监听
        itemClickListener(index, item) {
            // 选择的全部
            if (index === 1 && item.itemCode === 'all') {
                this.dataForm.itemCode = ''
            } else if (item.itemCode === 'all') {
                this.dataForm.itemCode = this.serviceItemChoose['itemChoose' + (index - 1)].itemCode
            } else {
                if (item.itemCode === '800101') {
                    // window.open('http://model.smartquality.cn/')
                    return
                }
                this.dataForm.itemCode = item.itemCode
            }
            this.handleItem()
            this.searchData()
        },
        // 获取产品列表
        getDataList(refresh) {
            if (refresh) {
                this.pageIndex = 1
            }
            this.loading = true
            // let filter = "itemCode:"+ itemCode + "*,serviceMethod:" + serviceMethod
            this.$http({
                url: this.$http.adornUrl(this.$api.NEED.NEEDLIST),
                methods: 'get',
                params: this.$http.adornParams({
                    // 'page': this.pageIndex,
                    // 'limit': this.pageSize,
                    // 'serviceName': this.dataForm.serviceName || null,
                    // 'itemCode': this.dataForm.itemCode || null ,
                    // 'orgCode': this.dataForm.orgCode || null,
                    // 'serviceMethod': this.dataForm.serviceMethod === 0 ? null : this.dataForm.serviceMethod,
                    // 'sortType': this.dataForm.sortType || null,
                    // 'priceLow': this.dataForm.priceLow || null,
                    // 'priceHigh': this.dataForm.priceHigh || null
                    'startPage': this.pageIndex - 1,
                    'pageSize': this.pageSize,
                    'needName': this.searchVal || null,
                    'itemCode': this.dataForm.itemCode || null,
                    'orgCode': this.dataForm.orgCode || null,
                    'serviceLabel': this.dataForm.serviceLabel,
                    'sortType': this.dataForm.sortType,
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    // let itemCode = localStorage.setItem('itemCode',this.dataForm.itemCode)
                    // this.dataList = data.page.list
                    // this.totalCount = data.page.totalCount
                    // this.totalPage = data.page.totalPage
                    this.dataList = data.result.list || []
                    if (this.isGetHotList) {
                       this.getHotList(data.result.list)
                    }
                    this.totalCount = data.result.total || 0
                    this.totalPage = Math.ceil(data.result.total / this.pageSize) || 1
                } else {
                    this.dataList = []
                    this.totalCount = 0
                    this.totalPage = 1
                }
                this.loading = false
            })
        },
        getHotList(list) {
            let n = list.length
            let one = Math.floor(Math.random() * n)
            let two = null
            for (var i = 0; i < n; i++) {
                two = Math.floor(Math.random() * n)
                if (one !== two) break
            }
            let rList = []
            rList.push(list[one])
            rList.push(list[two])
            this.hotList = rList
            this.isGetHotList = false

        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getDataList(true)
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getDataList(false)
        },
        chooseMethod(dictId) {
            this.dataForm.serviceMethod = dictId
            this.searchData()
        },
        chooseProvince(code) {
            this.dataForm.orgCode = code
            this.searchData()
        },
        searchData() {
            this.$nextTick(() => {
                this.getDataList(true)
            })
        },
        // 选择产品
        chooseProduct(item) {
            let itemCodeType = 'search'
            // this.keepAliveArr = ['search']
            this.$router.push({name: 'service', query: {id: item.id, itemCodeType: itemCodeType}})
        },
        //控制字数
        ellipsis(value) {
            if (!value) return ''
            if (value.length > 12) {
                return value.slice(0, 12) + '...'
            }
            return value
        },

        addOrFollow() {
            if (this.loginStatus) {
                this.addOrUpdateVisible = true
                this.$nextTick(() => {
                    this.$refs['addNeed'].init({
                        serviceId: this.dataForm.id,
                        needName: this.dataForm.needName,
                        itemCode: this.dataForm.itemCode,
                        itemName: this.dataForm.itemName
                    })
                })
            } else {
                this.$message.error('请您登录账号')
                this.$router.push({name: 'login'})
            }
        },
        closeCallback() {
            this.addOrUpdateVisible = false
        }
    }
}
</script>

<style scoped lang="scss">
.form-box {
    background: #fff;
    padding: 15px;
}
.a {
    color: #ff1f1f;
}
.search-box {
  width: 1330px;
  margin: auto;
  padding: 24px;
  font-size: 14px;
  background: #ffffff;
  border: 1px solid #EBEEF5;
}
.pagination {
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 8px;
}
.search-line {
  display: flex;
  width: 100%;
  /*line-height: 2.4rem;*/
  margin-bottom: 16px;
  border-bottom: 1px solid #F2F6FC;
  padding-bottom: 11px;
}

.search-line-label {
  display: flex;
  margin-right: 10px;
  text-align: right;
}

.search-line-label span {
  display: inline-block;
  height: 32px;
  line-height: 32px;
}

.search-line-label span:after {
  content: '：';
}

.search-line .search-line-content {
  flex: 1 1;
  width: 100%;
}

.search-line-content-box {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
}

.content-box-select {
  padding-right: 50px;
  position: relative;
  max-height: 32px;
  margin-left: -8px;
  overflow: hidden;
  line-height: 32px;
  transition: all .3s;
}

.content-box-select-expand {
  max-height: 200px;
  transition: all .3s;
}

.content-box-select-item {
  box-sizing: border-box;
  display: inline-block;
  margin-right: 16px;
  padding: 0 8px;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}

.content-box-select-item:hover {
  color: #ff9123;
}

.content-box-select .on {
  border-radius: 3px;
  color: #fff;
  background: #ff9123;
}

.search-price > > > .el-input__inner {
  border-radius: 0;
  padding: 0 5px;
}

.search-price > > > .el-button--mini {
  padding: 7px 10px;
}

.container {
  width: 1340px;
  margin: 0 auto;
  padding: 30px 15px 15px;
  box-sizing: border-box;
  line-height: 1;

  .items {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);;
    overflow: hidden;

    .item {
      background-color: #fff;
      padding: 15px;
      box-sizing: border-box;
      border-radius: 8px;
      box-shadow: 0 0 24px rgba(#000, 0.02);
      overflow: hidden;

      .img {
        width: 33%;
        aspect-ratio: 1;
        border-radius: 8px;
        overflow: hidden;
      }

      .company {
        font-size: 14px;
        color: #333;
      }

      .describe {
        font-size: 12px;
        color: #666;
      }
    }
  }
}
</style>
