<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="50%"
    top="5vh"
    @close="closeCallback(false)">
    <div class="i-title">
      <h3><i class="el-icon-s-order mr-1"></i>添加需求</h3>
    </div>
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="100px">
      <el-row><h3 style="color: #349bde;"><i class="mr-1"></i>基本信息：</h3></el-row>
      <el-row style="text-align: center">
        <el-col :span="8">
          <el-form-item label="姓名：" prop="publishName">
            <el-input v-model="dataForm.publishName"  placeholder="请输入姓名" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="性别：" prop="sex">
            <el-select v-model="dataForm.sex" style="width: 100%" placeholder="请选择性别">
              <el-option
                v-for="item in genderDict"
                :key="item.dictId"
                :label="item.dictName"
                :value="parseInt(item.dictId)">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="职位：" prop="position">
            <el-select v-model="dataForm.position" style="width: 100%" placeholder="请选择职位">
              <el-option
                v-for="item in positionDict"
                :key="item.dictId"
                :label="item.dictName"
                :value="item.dictId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="text-align: center">
        <el-col :span="8">
          <el-form-item label="手机：" prop="publishMobile">
            <el-input v-model="dataForm.publishMobile" placeholder="请输入手机号" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="座机：" prop="landline">
            <el-input v-model="dataForm.landline" placeholder="请输入座机" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="邮箱：" prop="mailbox">
            <el-input v-model="dataForm.mailbox" placeholder="请输入邮箱" clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="text-align: center">
        <el-col :span="8">
          <el-form-item label="QQ：" prop="qq">
            <el-input v-model="dataForm.qq" placeholder="请输入QQ号码" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="微信：" prop="wechat">
            <el-input v-model="dataForm.wechat" placeholder="请输入微信号" clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="text-align: center">
        <el-col :span="8">
          <el-form-item label="公司名称：" prop="company">
            <el-select v-model="dataForm.company" placeholder="请选择公司" style="">
              <el-option
                v-for="item in this.info"
                :key="item.enterpriseName"
                :label="item.enterpriseName"
                :value="item.enterpriseName">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="地址：" prop="address">
            <el-input v-model="dataForm.address" placeholder="请输入地址" clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row><h3 style="color: #349bde;"><i class="mr-1"></i>需求信息：</h3></el-row>
      <el-row style="text-align: center">
        <el-col :span="12">
          <el-form-item label="行业分类：" prop="itemName">
            <el-input v-model="dataForm.itemName" placeholder="产品服务项" style="width: 235px;margin-right: 10px" readonly></el-input>
            <el-popover
              v-model="itemVisible"
              placement="top"
              trigger="click">
              <service-item-cascader v-model="dataForm.itemCode" @clickItem="itemSelectListen"></service-item-cascader>
              <el-button type="primary" slot="reference">选择分类</el-button>
            </el-popover>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属地区：" prop="orgName">
            <el-input v-model="dataForm.orgName" placeholder="所属地区" style="width: 250px;margin-right: 3px" readonly></el-input>
            <el-popover
                v-model="orgVisible"
                placement="top"
                trigger="click">
              <org-select-popover @clickItem="orgSelectListen" :initCallback="false" :defaultCheck="false"></org-select-popover>
              <el-button type="primary" slot="reference">选择地区</el-button>
            </el-popover>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="text-align: center">
        <el-col :span="12">
          <el-form-item label="需求名称：" prop="needName">
            <el-input v-model="dataForm.needName" placeholder="需求名称" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="服务分类：" prop="serviceLabel">
            <el-select v-model="dataForm.serviceLabel" style="width: 100%" placeholder="请选择分类">
              <el-option
                  v-for="item in serviceLabelDictMap"
                  :key="item.dictId"
                  :label="item.dictName"
                  :value="item.dictId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="text-align: center">
        <el-col :span="24">
          <el-form-item label="需求备注：" prop="publishContent">
            <el-input
              type="textarea"
              :show-word-limit="true"
              maxlength="500"
              :autosize="{ minRows: 3, maxRows: 5}"
              v-model="dataForm.publishContent"
              placeholder="请输入需求备注"
              clearable>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeCallback(false)">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :loading="loading">确定</el-button>
    </span>
    <need-item-product ref="productDialog" v-if="productVisible" @closeCallback="productVisible = false" @choseProduct="choseProduct"></need-item-product>
   </el-dialog>
</template>

<script>
  import OrgSelectPopover from '../../../components/org-select-popover'
  import ServiceItemCascader from '../../../components/service-item-cascader'
  import {checkIsNull} from '@/utils'
  import NeedItemProduct from './need-item-product'
  import {isMobile, isEmail} from '../../../../utils/validate'

  export default {
    name: 'need-follow-or-add',
    components: {OrgSelectPopover, ServiceItemCascader, NeedItemProduct},
     computed: {
      userId: {
        get () { return this.$store.state.user.id }
      },
      name: {
        get () { return this.$store.state.user.name }
      },
      mobile: {
        get () { return this.$store.state.user.mobile }
      },
      info: {
        get () { return this.$store.state.user.info }
      },
      email: {
        get () { return this.$store.state.user.email }
      },
       orgCode: {
         get () { return this.$store.state.user.orgCode },
         set (val) { this.$store.commit('user/updateOrgCode', val) }
       },
       orgName: {
         get () { return this.$store.state.user.orgName },
         set (val) { this.$store.commit('user/updateOrgName', val) }
       }
    },
    data () {
      const checkMobile = (rule, value, callback) => {
        if (value === '') {
          return callback(new Error('请输入手机号码'))
        } else if (!isMobile(value)) {
          return callback(new Error('手机号码格式不正确'))
        } else {
          callback()
        }
      }
      const checkEmail = (rule, value, callback) => {
        if (value === '') {
          return callback(new Error('请输入邮箱'))
        } else if (!isEmail(value)) {
          return callback(new Error('邮箱格式不正确'))
        } else {
          callback()
        }
      }
      return {
        visible: false,
        orgVisible: false,
        itemVisible: false,
        dataForm: {
          id: '',
          publishName: '',
          sex: '',
          publishMobile: '',
          landline: '',
          mailbox: '',
          qq: '',
          wechat: '',
          address: '',
          company: '',
          nextFollowTime: '',
          serviceName: '',
          newFollowContent: '',
          source: '',
          staffName: '',
          quality: '',
          needName: '',
          serviceLabel: ''
        },
        dataRule: {
          publishName: [
            { required: true, message: '姓名不能为空', trigger: 'blur' }
          ],
          // itemName: [
          //   { required: true, message: '服务项不能为空', trigger: 'blur' }
          // ],
          serviceName: [
            { required: true, message: '意向产品不能为空', trigger: 'blur' }
          ],
          orgCode: [
            { required: true, message: '区域不能为空', trigger: 'blur' }
          ],
          needName: [
            { required: true, message: '需求名称不能为空', trigger: 'blur' }
          ],
          serviceLabel: [
            { required: true, message: '需求分类不能为空', trigger: 'blur' }
          ],
          mailbox: [
            {required: true, validator: checkEmail, trigger: 'blur'}
          ],
          publishMobile: [
            {required: true, validator: checkMobile, trigger: 'blur'}
          ]
        },
        dataListLoading: false,
        sourceDict: [],
        genderDict: [],
        positionDict: [],
        productDict: [],
        qualityDict: [],
        serviceLabelDictMap: [],
        choseProductVisable: true,
        productVisible: false,
        staffVisible: false,
        loading: false
      }
    },
    created () {
      this.initDict()
    },
    methods: {
      initDict () {
        this.$getDictList('GENDER,NEED_QUALITY,NEED_SOURCE,POSITION,SERVICE_LABEL', (dict) => {
          this.genderDict = dict.GENDER
          this.qualityDict = dict.NEED_QUALITY
          this.sourceDict = dict.NEED_SOURCE
          this.positionDict = dict.POSITION
          this.genderDict.splice(0,1)
          this.genderDict.splice(2,1)
          this.serviceLabelDictMap = dict.SERVICE_LABEL
        })
      },
      // 初始化
      init (data) {
        this.visible = true
        this.dataForm.userId = this.userId
        this.dataForm.publishName = this.name
        this.dataForm.publishMobile = this.mobile
        this.dataForm.mailbox = this.email
        if (!checkIsNull(this.dataForm.orgCode)) {
          this.dataForm.orgCode = this.orgCode
          this.dataForm.orgName = this.orgName
        } else {
          this.dataForm.orgCode = '00'
          this.dataForm.orgName = '全国'
        }

        if (!checkIsNull(this.info)) {
            this.dataForm.company = this.info.enterpriseName
            this.dataForm.address = this.info.enterpriseAddress
          }
        if (!checkIsNull(data)) {
          let item = this.$deepClone(data)
          this.dataForm.serviceId = item.serviceId
          this.dataForm.serviceName = item.serviceName
          this.dataForm.itemCode = item.itemCode
          this.dataForm.itemName = item.itemName
          this.choseProductVisable = false
        }
        if (this.info.length===1){
          this.dataForm.company = this.info[0].enterpriseName
        }
      },
      closeCallback (refresh) {
        this.visible = false
        this.$emit('closeCallback', refresh)
      },
      orgSelectListen (orgCode, orgName) {
        this.dataForm.orgCode = orgCode
        this.dataForm.orgName = orgName
        this.orgVisible = false
        this.$forceUpdate()
      },
      getNeedInfo (id) {
        this.$http({
          url: this.$http.adornUrl(this.$api.NEED.INFO + '/' + id),
          method: 'post'
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataForm = data.data
            this.dataForm.nextFollowTime = ''
          } else {
            this.$message.error(data.msg)
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
          this.$refs['dataForm'].validate((valid) => {
            if (valid) {
              this.$confirm('确定添加需求?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.loading = true
                this.$http({
                  url: this.$http.adornUrl(this.$api.NEED.SUBMIT),
                  method: 'post',
                  data: this.$http.adornData(this.dataForm)
                }).then(({data}) => {
                  if (data && data.code === 0) {
                    this.loading = false
                    this.$message({
                      message: '操作成功',
                      type: 'success',
                      duration: 1500,
                      onClose: this.closeCallback(true)
                    })
                  } else {
                    this.$message.error(data.msg)
                    this.loading = false
                  }
                })
              })
            }
          })
      },
      handleChange (val) {
        this.dataForm.productId = val[1].id
        this.dataForm.serviceName = val[1].serviceName
        this.dataForm.itemCode = val[1].itemCode
        this.dataForm.itemName = val[1].itemName
      },
      itemSelectListen (val) {
        this.dataForm.itemCode = val.itemCode
        this.dataForm.itemName = val.itemName
        if (!checkIsNull(this.dataForm.itemCode)) {
          this.choseProductVisable = false
          this.dataForm.serviceId = ''
          this.dataForm.serviceName = ''
        } else {
          this.choseProductVisable = true
          this.dataForm.serviceId = ''
          this.dataForm.serviceName = ''
        }
        this.itemVisible = false
      },
      itemProductHandle () {
        this.productVisible = true
        this.$nextTick(() => {
          this.$refs['productDialog'].init(this.dataForm.itemCode, this.dataForm.itemName)
        })
      },
      choseProduct (serviceInfo) {
        this.dataForm.serviceId = serviceInfo.id
        this.dataForm.serviceName = serviceInfo.serviceName
      }
    }
  }
</script>

<style scoped>
  .i-title h3 {
    margin: 7px 0 12px;
    font-weight: bold;
    font-size: 17px;
    color: #349bde;
    padding-bottom: 12px;
    border-bottom: 1px dashed #E4E7ED;
  }

</style>
